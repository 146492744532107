@font-face {
  font-family: "Circular";
  src: local("Circular"), url(./font/CircularXX-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Playball";
  src: local("Playball"), url(./font/PrequelDemo-Regular.ttf) format("truetype");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Circular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
