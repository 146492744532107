.header {
  width: 100%;
  height: 95px;
  position: fixed;
  background-color: $color-primary;
  z-index: 999;

  .logo {
    height: 80%;
    width: 10%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .header-items {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 4px));
    font-size: 1.3rem;

    .nav-item {
      position: relative;

      transition: all 0.3s;

      &::after {
        content: "";
        position: absolute;
        width: 0px;
        height: 0px;
        background-image: linear-gradient(315deg, $color-accent 0%, #f2cf43 100%);
        left: 50%;
        bottom: -15px;
        transform: translateX(-50%);
        border-radius: 8px;
        transition: all 0.3s;
      }

      &.active {
        &::after {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  a {
    all: unset;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    height: 60px;
    .header-items {
      visibility: hidden;
    }

    .logo {
      left: 50%;
      transform: translate(-50%, -50%);
      height: 90%;
      width: inherit;
    }
  }
}
