$color-primary: #333;
// $color-accent: #9cbc3d;
$color-accent: rgb(0, 149, 69);

@import "./header.scss";

.index {
  width: 100vw;
  height: 100vh;
  background-color: $color-primary;
  color: white;

  :target {
    scroll-margin-top: 95px;
  }

  h1 {
    font-size: 66px;
    line-height: 76px;
    font-weight: 700;
    letter-spacing: 0px;
    margin: 15px 0 0 0;
  }

  h2 {
    font-size: 44px;
    line-height: 54px;
    font-weight: 700;
    margin: 0;

    &.cursive {
      color: $color-accent;
      font-family: "Playball";
    }
  }

  p {
    font-size: 1.5rem;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.4);
    opacity: 0.8;
  }

  //sections
  section {
    // padding-top: 95px;
    height: 100%;
    width: 100%;
    // position: relative;
  }
  #home {
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;

    .content {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);
      max-width: 40%;
      h1 {
        span {
          font-size: 54px;
          line-height: 66px;
          font-family: "Circular";
          display: grid;
        }
      }
      .buttons {
        height: 5vh;
        button {
          border-radius: 5px;
          font-family: "Circular";
          font-size: 16px;
          width: 20%;
          margin-right: 5%;
          height: 100%;
          border: none;
          position: relative;

          a {
            all: unset;

            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          &#more {
            background-color: $color-accent;
            color: #ffffff;
            transition: all 0.3s;
            &:hover {
              background-color: #f2f2f2;
              color: #2f2f2f;
            }
          }
          &#contact {
            background-color: #f2f2f2;
            color: #2f2f2f;
            transition: all 0.3s;
            &:hover {
              background-color: $color-accent;
              color: #ffffff;
            }
          }
        }
      }
    }

    img {
      height: 80%;
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translateY(-45%);
    }
  }

  #banner {
    height: 100%;
    position: relative;

    .text {
      position: absolute;
      top: 0;
      background-color: $color-primary;
      width: 100%;
      text-align: center;
      h1,
      h2 {
        margin: 0;
      }
    }

    img {
      position: absolute;
      top: 0;
      height: 100%;
    }
  }

  #iconBanner {
    height: 35%;
    position: relative;
    background-color: $color-primary;
    .icons {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      align-items: center;

      img {
        height: 75%;
        filter: invert(0.9) drop-shadow(2px 4px 6px black) brightness(0.9);
      }
    }
  }

  #leistungen {
    height: fit-content;
    .category {
      width: 100vw;
      height: 60vh;
      display: grid;
      position: relative;
      grid-template-columns: 50% 50%;
      grid-template-rows: max-content;

      .content {
        grid-area: header;
        padding-left: 30px;
        padding-top: 30px;
        width: 80%;
      }

      &.dark {
        background-color: #2f2f2f;
        color: #f2f2f2;
      }

      &.light {
        background-color: #2f2f2f;
        color: #f2f2f2;
      }

      &.imgRight {
        grid-template-areas:
          "header content2"
          "content1 content2";
        .list {
          grid-area: content1;
        }
        .img {
          grid-area: content2;
        }
      }

      &.imgLeft {
        grid-template-areas:
          "content1 header"
          "content1 content2";
        .list {
          grid-area: content2;
        }
        .img {
          grid-area: content1;
        }
      }

      .list {
        padding-left: 60px;
        .item {
          font-size: 34px;

          p {
            margin: 0;
            padding: 30px 0px;
          }
        }
      }

      .tab {
        height: 100%;
      }

      .img {
        background-size: cover;
        z-index: 1;
      }
    }
  }

  #banner2 {
    background-size: cover;
    background-position: center;
    position: relative;

    .print {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      max-width: 1300px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 70%;

      img {
        height: 100%;
        max-height: 40vh;
      }

      h1 {
        width: 100%;
        height: fit-content;
        text-align: center;
      }
    }
  }

  #kontakt {
    height: fit-content;
    position: relative;
    display: flex;
    background-color: $color-primary;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 30px;

    a {
      all: unset;
    }

    .bar {
      width: 25%;
    }

    h3 {
      font-size: 3rem;
      margin: 0;
    }

    .list {
      display: flex;
      flex-direction: column;
      line-height: 24px;

      a::after {
        content: "";
        width: 100%;
        height: 0.1px;
        background-color: #f2f2f2;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        border-bottom: 1px solid;
        clear: both;
        display: block;
        position: relative;
        width: 100%;
        border-color: rgba(255, 255, 255, 0.2);

        a {
          position: relative;
          padding-top: 0;
          padding: 10px 0 10px 30px;
          display: inline-block;
          line-height: 24px;
          color: #fff;
          width: 100%;
          white-space: pre;
        }
      }
    }
  }
  .impress {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    z-index: 999;
    transition: left 0.3s;
    box-sizing: border-box;
    padding: 5%;

    &.active {
      left: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .index {
    overflow-x: hidden;

    h1 {
      font-size: 2.5rem;
      line-height: 2.5rem;

      span {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }

    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }

    #home {
      .content {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translateY(-50%);
        max-width: 100%;
        padding: 5%;
        box-sizing: border-box;
        width: 100%;

        h1 {
          font-size: 2.5rem;
          line-height: 2.5rem;

          span {
            font-size: 2.5rem;
            line-height: 2.5rem;
          }
        }
      }

      .buttons {
        visibility: hidden;
      }
    }

    #iconBanner {
      height: 100%;

      .icons {
        flex-direction: column;

        img {
          height: auto;
          width: 60%;
        }
      }
    }

    #leistungen {
      .category {
        height: auto;
        grid-template-columns: 100%;
        &.imgRight {
          grid-template-areas:
            "header"
            "content1"
            "content2";
        }
        &.imgLeft {
          grid-template-areas:
            "header"
            "content2"
            "content1";
        }
        .list {
          padding: revert;
          .item {
            font-size: 1.5rem;
            p {
              padding: 15px 0px;
              margin: 0;
            }
          }
        }

        .content {
          padding-left: 2%;
          padding-top: 2%;
          width: 96%;
          box-sizing: border-box;
          p {
            margin: 0;
          }
        }

        .tab {
          width: 100%;
          height: fit-content;
        }

        .img {
          height: calc(100vw / 16 * 9);
        }

        .text {
          h2 {
            &::after {
              width: 75px;
            }
          }
        }
      }
    }

    #banner2 {
      .print {
        flex-direction: column;
        img {
          width: 50%;
          height: auto;
          left: 50%;
          top: 50%;
        }
        h1 {
          width: 100%;
          transform: translate(0%);
          text-align: center;
        }
      }
    }

    #iconBanner {
      visibility: hidden;
      height: 0;
    }

    #kontakt {
      flex-direction: column;

      h3 {
        font-size: 2rem;
        text-align: center;
      }

      .bar {
        width: 100%;
        padding-bottom: 10%;
      }
      ul > li > a {
        text-align: center;
        padding: 10px 0px;

        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}
